
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { appGlobalConfig } from "@/core/helpers/config";
import store from "@/store";

export default defineComponent({
  name: "pending-invoices",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      signInLoader: false,
      refs: "invoices/pending",
      items: [],
      row: {
        country_code: "+965",
        civil_id: "",
        mobile: "",
      },
    };
  },
  methods: {
    // fetch Data
    fetchData() {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        // Authorization: `Bearer ` + this.auth.id_token,
      };

      this.axios({
        url: this.refs,
        method: "POST",
        data: {
          mobile: this.row.mobile,
          civil_id: this.row.civil_id,
          country_code: this.row.country_code,
        },
        params: {},
      })
        .then((res) => {
          this.items = res.data.rows;
          if (this.items.length >= 1) {
            console.log("done");
          } else {
            Swal.fire({
              text: this.translate("app.no_exist_pending_invoices"),
              buttonsStyling: false,
              confirmButtonText: this.translate("app.cancel"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            text: this.translate("app.invalid_credentials"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: this.translate("app.cancel"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    },
    translate: function (text: string) {
      if (this.te(text)) {
        return this.t(text);
      } else {
        return text;
      }
    },
  },
  setup() {
    //Create form validation object
    const { t, te } = useI18n();

    const login = Yup.object().shape({
      mobile: Yup.string().required().label("Mobile"),
      civil_id: Yup.string().min(4).required().label("Civil_id"),
    });

    return {
      login,
      t,
      te,
      appGlobalConfig,
    };
  },
});
